/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

input.ng-invalid.ng-touched {
    border: 1px solid red;
}
ng-select.ng-invalid.ng-touched {
    border: 1px solid red;
}
select.ng-invalid.ng-touched {
    border: 1px solid red;
}
textarea.ng-invalid.ng-touched {
    border: 1px solid red;
}
.required-field {
    color: red;
}

.btn-create {
    margin-left: 45%;
}
